import { StagesEnum } from "../common/constants/enums";

export interface ProjectDetails {
    id: string;
    collectionId: string;
    collectionName: string;
    created: string;
    updated: string;
    name: string;
    stage: number;
    description: string;
    CurrentChangeLog: ChangeLog;
    associatedNotes?: string[];
  }

  export interface Notes {
    Note: string
    collectionId: string
    collectionName: string
    created: string
    id: string
    updated: string  
  }

  export interface ChangeLog {
    id: string;
    projectId: string,
    type: number,
    collectionId: string;
    collectionName: string;
    created: Date;
    Notes: Notes[];
    updated: string;
    note: string;
    stage: number;
  }


  export function getStageInfo(stage: StagesEnum) {
    switch (stage) {
      case StagesEnum.Analyze:
        return "Analyze/Question";
      case StagesEnum.Develop:
        return "Develop";
      case StagesEnum.Review:
        return "Review Code";
      case StagesEnum.UnitTest:
        return "Create Unit Test";
      case StagesEnum.CodeReview:
        return "Code Review";
      case StagesEnum.Push:
        return "Push to Develop";
      case StagesEnum.Qa:
        return "Ready for QA";
      default:
        return "Unknown";
    }
  }

  export function getStageColors(stage: StagesEnum) {
    switch (stage) {
      case StagesEnum.Analyze:
        return "bg-amber-600";
      case StagesEnum.Develop:
        return "bg-neutral-500";
      case StagesEnum.Review:
        return "bg-green-600";
      case StagesEnum.UnitTest:
        return "bg-teal-600";
      case StagesEnum.CodeReview:
        return "bg-fuchsia-600";
      case StagesEnum.Push:
        return "bg-rose-600";
      case StagesEnum.Qa:
        return "bg-blue-600";
      default:
        return "Unknown";
    }
  }

