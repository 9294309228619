export enum StagesEnum {
    Analyze = 0,
    Develop = 1,
    Review = 2,
    UnitTest = 3,
    CodeReview = 4,
    Push = 5,
    Qa = 6,
  }

  export enum ChangeLogType{
    StageUpdate,
    NoteAddition
  }
