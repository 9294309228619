import { makeObservable, makeAutoObservable, runInAction } from 'mobx';
import { ChangeLog, ProjectDetails } from '../../models/projectModels';
import pb, { fetchAllProjectData, fetchChangeLog, fetchProjectData } from '../constants/pocketbase';

export default class ProjectStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingInitial = false;
    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    };

    filter = -1;
    setFilter = (state: number) => {
        this.filter = state;
    };


    selectedProjectDetails: ProjectDetails | undefined = undefined;
    selectedProjectChangeLogRegistry = new Map<string, ChangeLog>();
    projectReigstry = new Map<string, ProjectDetails>();

    loadSelectedProjectDetails = async (id: string) => {
        try {
            this.setLoadingInitial(true);
            const result = await fetchProjectData(id);
            runInAction(() => {
                this.selectedProjectDetails = result
            });
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoadingInitial(false);
        }
    }

    loadSelectedProjectChangeLog = async (id: string) => {
        try {
            this.setLoadingInitial(true);
            this.selectedProjectChangeLogRegistry.clear();
            const result = await fetchChangeLog(id);
            runInAction(() => {
                result.forEach((changeItem) => {
                    this.setProjectChangeLog(changeItem);
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoadingInitial(false);
        }
    }
    loadProjects = async (filter: number) => {
        try {
            this.setLoadingInitial(true);
            this.projectReigstry.clear();
            const result = await fetchAllProjectData(filter);
            runInAction(() => {
                result.forEach((project) => {
                    this.setProjects(project);
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoadingInitial(false);
        }
    }


    updateNextStage = async (project: ProjectDetails) => {
        try {
            const createData = {
                "ProjectId": project.id,
                "stage": project.stage + 1,
                "Note": "",
                "Type": 1
            };

            const record = await pb.collection('ChangeLog').create(createData);
            const data = {
                "name": project.name,
                "stage": project.stage + 1,
                "description": project.description,
                "CurrentChangeLog": record.id

            };
            await pb.collection('Projects').update(project.id, data);

            this.loadSelectedProjectChangeLog(project.id);
            this.loadSelectedProjectDetails(project.id);
        } catch (e) {
            console.log(e);
        }
    }

    rollbackStage = async (project: ProjectDetails, stage: number, notes: string) => {
        try {
            const NotesData = {
                "Note": notes
            };
            const notesRecord = await pb.collection('Notes').create(NotesData);

            const newNote = [notesRecord.id]
            const createData = {
                "ProjectId": project.id,
                "stage": stage,
                "Notes": newNote,
                "type": 2
            };

            const record = await pb.collection('ChangeLog').create(createData);
            const data = {
                "name": project.name,
                "stage": stage,
                "description": project.description,
                "CurrentChangeLog": record.id

            };
            await pb.collection('Projects').update(project.id, data);

            this.loadSelectedProjectChangeLog(project.id);
            this.loadSelectedProjectDetails(project.id);
        } catch (e) {
            console.log(e);
        }
    }

    updateDescription = async (project: ProjectDetails, description: string) => {
        try {
            const data = {
                "name": project.name,
                "stage": project.stage,
                "description": description
            };
            await pb.collection('Projects').update(project.id, data);
            this.loadSelectedProjectDetails(project.id);
        } catch (error) {
            console.log(error)
        }
    }

    createCurrentNote = async (project: ProjectDetails, note: string) => {
        try {
            const NotesData = {
                "Note": note
            };
            const record = await pb.collection('Notes').create(NotesData);

            const noteIds = [];

            project.associatedNotes?.map((x) => noteIds.push(x));
            noteIds.push(record.id);

            const ChangeLogData = {
                "ProjectId": project.id,
                "stage": project.stage,
                "Note": "test",
                "Type": 0,
                "Notes": noteIds
            }
            await pb.collection('ChangeLog').update(project.CurrentChangeLog.id, ChangeLogData);
            this.loadSelectedProjectChangeLog(project.id);
            this.loadSelectedProjectDetails(project.id);
        } catch (error) {
            console.error(error);
        }
    }

    createNote = async (changeLog: ChangeLog, note: string) => {
        try {
            const NotesData = {
                "Note": note
            };
            const record = await pb.collection('Notes').create(NotesData);

            const noteIds = [];
            changeLog.Notes.map((x) => noteIds.push(x.id))
            noteIds.push(record.id);

            const ChangeLogData = {
                "ProjectId": changeLog.projectId,
                "stage": changeLog.stage,
                "Type": 0,
                "Notes": noteIds
            }
            await pb.collection('ChangeLog').update(changeLog.id, ChangeLogData);
            this.loadSelectedProjectChangeLog(changeLog.projectId);
            this.loadSelectedProjectDetails(changeLog.projectId);
        } catch (error) {
            console.error(error);
        }
    }

    deleteProject = async (id: string) => {
        try {
            await pb.collection('Projects').delete(id);
        } catch (error) {
            console.error(error);
        }
    }

    createTask = async (name:string, description:string) =>{
        try{
            const projectData = {
                "name": name,
                "stage": 0,
                "description": description,

            };
            const projectRecord = await pb.collection('Projects').create(projectData);
            
            const changeLogData = {
                "ProjectId": projectRecord.id,
                "Type": 0,
                "stage": 0,
            };
            const changeLogRecord = await pb.collection('ChangeLog').create(changeLogData);


            const updateProjectData = {
                "name": name,
                "stage": 0,
                "description": description,
                "CurrentChangeLog": changeLogRecord.id
            };

            await pb.collection('Projects').update(projectRecord.id,updateProjectData);
            this.loadProjects(-1);            
        }
        catch (error) {
            console.error(error);
        }
    }

    private setProjectChangeLog = (changeItem: ChangeLog) => {
        this.selectedProjectChangeLogRegistry.set(
            changeItem.id,
            changeItem
        );
    };
    private setProjects = (p: ProjectDetails) => {
        this.projectReigstry.set(
            p.id,
            p
        );
    };

    get getSelectedProjectChangeLog() {
        return Array.from(this.selectedProjectChangeLogRegistry.values()).sort(
            (a, b) => {
                const descriptionA = a.created;
                const descriptionB = b.created;

                if (descriptionA < descriptionB) return 1;
                if (descriptionA > descriptionB) return -1;
                return 0;
            }
        );
    }
    get getProjects() {
        return Array.from(this.projectReigstry.values()).sort(
            (a, b) => {
                const descriptionA = a.updated;
                const descriptionB = b.updated;

                if (descriptionA < descriptionB) return 1;
                if (descriptionA > descriptionB) return -1;
                return 0;
            }
        );
    }

}