import ReactTimeAgo from "react-time-ago"
import { ChangeLog, getStageInfo } from "../app/models/projectModels"
import MyTextAreaModal from "./MyTextAreaModal"
import { useState } from "react";
import { useStore } from "../app/common/store/store";
import { observer } from "mobx-react";

interface ChangeLogProps {
    logItem: ChangeLog
}

export default observer(function ChangeLogTimeLine(props: ChangeLogProps) {
    const [showNotesModal, setShowNotesModal] = useState(false);

    const { projectStore } = useStore();
    const{createNote} = projectStore;

    return (
        <>
            <li className="mb-5 ml-6 mr-6 mt-3">
                <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
                    <div className="items-center justify-between mb-8 sm:flex ">
                        <time className="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0 mr-3">{props.logItem.created.toLocaleDateString() + " " + props.logItem.created.toLocaleTimeString()}</time>
                        <div className="text-sm font-normal text-gray-500 lex dark:text-gray-300">The task was {props.logItem.type == 2 ? "rolledback " : "updated "} to the <b>{getStageInfo(props.logItem.stage)}</b> stage</div>
                    </div>

                    {props.logItem.Notes?.map((notes, index) => (
                        <>
                            <div className="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">{notes.Note}
                                <br></br></div>
                            <div className="grid justify-items-end m-3 mb-4">
                                <time className="text-xs font-normal text-gray-400">
                                    <ReactTimeAgo date={new Date(notes.created)} locale="en-US" />
                                </time>
                            </div>
                        </>
                    ))}

                    <div className="flex items-center mt-4 space-x-4">
                        <button type="button" onClick={() => setShowNotesModal(!showNotesModal)}
                            className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                            <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                            </svg>  
                            Add Note
                        </button>
                    </div>
                    {showNotesModal ?
                        <MyTextAreaModal name={"Add Note To " + getStageInfo(props.logItem.stage)} onUpdate={() => setShowNotesModal(!showNotesModal)} onSubmit={((x) => createNote(props.logItem, x).finally(() => setShowNotesModal(!showNotesModal)))} />

                        : null}


                </div>
            </li>
        </>
    )
})