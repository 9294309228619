import PocketBase from 'pocketbase';
import { ChangeLog, ProjectDetails } from '../../models/projectModels';

const pb = new PocketBase('https://kilobyte.pockethost.io');


export async function fetchProjectData(projectid: string) {
  const record = await pb.collection('Projects').getOne(projectid,{expand: 'CurrentChangeLog,CurrentChangeLog.Notes'});
  const projectData: ProjectDetails = {
    id: record.id,
    collectionId: record.collectionId,
    collectionName: record.collectionName,
    CurrentChangeLog: record.expand?.CurrentChangeLog,
    created: record.created,
    updated: record.updated,
    name: record.name,
    stage: record.stage,
    description: record.description,
    associatedNotes: record.expand?.CurrentChangeLog.Notes
  };
  return projectData;


  }

  export async function fetchAllProjectData(filter: number) {
    const record = filter != -1 ? await pb.collection('Projects').getFullList({
      filter: "stage = '"+filter+"'"}) : await pb.collection('Projects').getFullList();
    const projectData: ProjectDetails[] = record.map(x => ({
      id: x.id,
      collectionId: x.collectionId,
      collectionName: x.collectionName,
      CurrentChangeLog: x.expand?.CurrentChangeLog,
      created: x.created,
      updated: x.updated,
      name: x.name,
      stage: x.stage,
      description: x.description,
    }));
    return projectData;
  }

export async function fetchChangeLog(projectid: string) {
  const record = await pb.collection('ChangeLog').getFullList({filter: "ProjectId = '"+projectid+"'", sort: '-created', expand: 'Notes'});
  const changeLogs: ChangeLog[] = record.map(x => ({
    id: x.id,
    projectId: x.ProjectId,
    type: x.type,
    collectionId: x.collectionId,
    collectionName: x.collectionName,
    Notes:x.expand?.Notes,
    created: new Date(x.created),
    updated: x.updated,
    note: x.Note,
    stage: x.stage,
    associatedNotes:x.expand?.Notes
  }));
  return changeLogs;
}


export async function fetchProjectDetails() {
  try {
    const response = await fetchProjectData("gpiynglwt9nroj7");
    return response;
  } catch (error) {
    console.error("Error fetching project data: ", error);
  }
}


export async function addProjectNote(project: ProjectDetails, note: string) {
  try {
    const data = {
      "ProjectId": project.id,
      "stage": project.stage,
      "Note": note,
      "Type": 0
    };
    const record = await pb.collection('ChangeLog').create(data);
  } catch (error) {
    console.error("Error fetching project data: ", error);
  }
}



export default pb;
