import React from 'react';
import { Route, Link, BrowserRouter, Routes } from 'react-router-dom';
import Home from './features/Home';
import Layout from './features/Layout';
import Project from './features/project/Project';
import { StoreContext, store } from './app/common/store/store';

function App() {
  return (
    <StoreContext.Provider value={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/project/:id' element={<Project />} />
        </Routes>
      </BrowserRouter>
    </StoreContext.Provider>
  );
}

export default App;
