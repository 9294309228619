import { observer } from "mobx-react";
import { useStore } from "../app/common/store/store";
import { useEffect, useState } from "react";
import { getStageColors, getStageInfo } from "../app/models/projectModels";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { StagesEnum } from "../app/common/constants/enums";


export default observer(function Home() {
    const navigate = useNavigate();

    const { projectStore } = useStore();
    const { loadingInitial, getProjects, loadProjects, createTask } = projectStore
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const [modal, setModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<number>(-1);

    useEffect(() => {
        loadProjects(filter);
    }, [filter]);

    return (
        <main className="flex flex-col justify-between p-16">
        <Loader loading={loadingInitial}/>


            <div className="mb-6 pb-3 border-b-2 ">
                <>
                    <h1 className="text-lg font-semibold">KiloByte</h1>
                    <h2>hmmm what am I working on and where is it at?</h2>
                </>

            </div>
            <div className="flex">

                <button onClick={()=>setModal(!modal)} type="button" className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                    <div className="fa fa-chevron-right"></div>
                    <p className="ml-1">Create Task</p>
                </button>
                <select
  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
  onChange={(e) => setFilter(Number(e.target.value))}
>
<option value={-1}>
      All Stages
    </option>

  {Array.from({ length: 7 }, (_, i) => (
    <option key={i} value={i}>
      {getStageInfo(i)}
    </option>
  ))}
</select>


            </div>

            <div className="flex flex-wrap mt-5">

            {getProjects.map((x) =>
                    <div className="p-2 max-w-md min-w-lg">
                        <div className={"flex rounded-lg h-full dark:bg-gray-800 p-8 flex-col "+getStageColors(x.stage)}>
                            <div className="flex items-center mb-3">
                                <div
                                    className=" h-8 mr-1 inline-flex items-center justify-center rounded-full dark:bg-indigo-500 text-white flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
                                    </svg>
                                </div>
                                <h2 className="text-white dark:text-white text-md font-medium">{x.name}</h2>
                            </div>
                            <div className="flex flex-col justify-between flex-grow">
                                <p className="leading-relaxed text-base text-white dark:text-gray-300">
                                    {getStageInfo(x.stage)}
                                </p>
                                <p className="leading-relaxed text-base text-white dark:text-gray-300 mb-5">
                                    {x.description}
                                </p>
                                <time className="text-xs font-normal text-white">
                                    <ReactTimeAgo date={new Date(x.created)} locale="en-US" />
                                </time>

                                <button onClick={() => navigate("/project/"+x.id)} className="mt-1 text-white dark:text-white hover:text-blue-600 inline-flex items-center">View
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
            )}
                </div>

{modal ?
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Create A New Task
                        </h3>
                        <button onClick={()=> setModal(!modal)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 space-y-6">
                        <input onChange={(e) => setTitle(e.target.value)}  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Task Name"></input>
                        <textarea onChange={(e) => setDescription(e.target.value)} rows={3} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Description"></textarea>
                    </div>
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button onClick={() => createTask(title, description).then(()=>setModal(!modal))} data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create</button>
                        <button onClick={()=> setModal(!modal)} data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" >Cancel</button>
                    </div>
                </div>
            </div>
        </div>


: null}

        </main>
    )
})