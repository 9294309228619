import { useEffect, useState } from "react";
import MyTextAreaModal from "../../components/MyTextAreaModal";
import { getStageInfo } from "../../app/models/projectModels";
import { useStore } from "../../app/common/store/store";
import { observer } from "mobx-react";
import Loader from "../../components/Loader";
import ChangeLogTimeLine from "../../components/ChangeLog";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default observer(function Project() {
  const navigate = useNavigate();

  const [showDescriptionModal, setShowDescirptionModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showRollbackModal, setShowRollbackModal] = useState(false);
  const [rollbackStageNumber, setRollbackStageNumber] = useState<number>(0);
  const [rollbackNotes, setRollbackNotes] = useState<string>("");

  const [update, setUpdate] = useState<boolean>(true);
  const { id } = useParams();

  const { projectStore } = useStore();
  const { loadingInitial, rollbackStage, loadSelectedProjectDetails, selectedProjectDetails, getSelectedProjectChangeLog, loadSelectedProjectChangeLog,updateNextStage, updateDescription, createCurrentNote, deleteProject } = projectStore

  useEffect(() => {
    loadSelectedProjectDetails(id!);
    loadSelectedProjectChangeLog(id!);
  }, []);


  return (
    <>
      <Loader loading={loadingInitial} />
      <main className="flex flex-col justify-between p-16">

        <div className="mb-6 pb-3 border-b-2 ">
          {selectedProjectDetails ? (
            <>
              <h1 className="text-lg font-semibold">{selectedProjectDetails.name} - {getStageInfo(selectedProjectDetails.stage)}</h1>
              <h2>{selectedProjectDetails.description}</h2>
            </>
          ) : null}
        </div>
        <div className="flex">
          <button type="button" onClick={() => updateNextStage(selectedProjectDetails!)} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
            <div className="fa fa-chevron-right"></div>
            <p className="ml-1">Next Stage</p>
          </button>

          <button type="button" onClick={() => setShowDescirptionModal(!showDescriptionModal)} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
            <div className="fa  fa-chevron-up"></div>
            <p className="ml-1">Update Description</p>
          </button>

          <button type="button" onClick={() => deleteProject(selectedProjectDetails?.id!).then(() => navigate("/"))} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
            <div className="fa fa-times"></div>
            <p className="ml-1">Delete</p>
          </button>
          <button type="button" onClick={() => setShowNotesModal(!showNotesModal)} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
            <div className="fa fa-plus"></div>
            <p className="ml-1">Add Note</p>
          </button>
          <button type="button" onClick={() => setShowRollbackModal(!showRollbackModal)} className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
            <div className="fa fa-plus"></div>
            <p className="ml-1">Rollback Stage</p>
          </button>
        </div>


        {showDescriptionModal ? (
          <MyTextAreaModal name="Update Description" currentValue={selectedProjectDetails?.description} onUpdate={() => setShowDescirptionModal(!showDescriptionModal)} onSubmit={(x: string) => updateDescription(selectedProjectDetails!, x).finally(() => setShowDescirptionModal(!showDescriptionModal))} />
        ) : null}
        {showNotesModal ? (
          <MyTextAreaModal name="Add Notes" onUpdate={() => setShowNotesModal(!showNotesModal)} onSubmit={(x: string) => createCurrentNote(selectedProjectDetails!, x).then(() => setShowNotesModal(!showNotesModal))} />
        ) : null}


        {showRollbackModal ? (        
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Rollback
                        </h3>
                        <button onClick={() => setShowRollbackModal(!showRollbackModal)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 space-y-6">
                    <select
  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
  onChange={(e) => setRollbackStageNumber(Number(e.target.value))}
>
<option selected disabled>
      Select Stage
    </option>

  {Array.from({ length: 7 }, (_, i) => (
    <option key={i} value={i}>
      {getStageInfo(i)}
    </option>
  ))}
</select>

<p>Why are you rolling back?</p>
                        <textarea rows={5} onChange={(e) => setRollbackNotes(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>

                    </div>
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button onClick={() => rollbackStage(selectedProjectDetails!, rollbackStageNumber, rollbackNotes).then(() => setShowRollbackModal(!showRollbackModal)) }data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                        <button onClick={() => setShowRollbackModal(!showRollbackModal)} data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
) : null}




        <>
          <div>
            <ol>
              {getSelectedProjectChangeLog?.map((log, index) => (
                <ChangeLogTimeLine logItem={log}/>
              ))}
            </ol>
          </div>
        </ >

      </main>
    </ >

  )
})